/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import withLoader from '../../helpers/withloader';
import { AutoCompleteCompleteMethodParams, ITrekLookupModel, IUserLookupModel } from '../../models/index';
import { AutoComplete } from 'primereact/autocomplete';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { ITrekSeasons } from '../../models/Trek/TrekSeasons';
import BatchService from '../../api/BatchService';
import { IBatchModel } from '../../models/Batch/BatchFormModel';
import { IBatchApiModel } from '../../models/Batch/BatchApiModel';
import { BatchPromoter } from '../../models/Batch/BatchPromoterLookupModel';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';
import { Checkbox } from 'primereact/checkbox';
import { compareDates } from '../../helpers/utils';
import Overlay from '../common/Overlay';

const BatchDetail: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);
  const isAddMode = !id;
  const toast = useRef(null);

  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [selectedTrekValue, setSelectedTrekValue] = useState<ITrekLookupModel | null>(null);
  const [selectedSpecialTrekValue, setSelectedSpecialTrekValue] = useState<ISpecialTrekTypeModel | null>(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState<ITrekLookupModel[]>([]);

  const [autoSeasonsValueData, setAutoSeasonsValueData] = useState<ITrekSeasons[]>([]);
  const [autoSeasonsFilteredValue, setSeasonsFilteredValue] = useState<ITrekSeasons[]>([]);

  const [autoLeaderUserData, setAutoLeaderUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredLeaderUserValue, setAutoLeaderTransportUserValue] = useState<IUserLookupModel[]>([]);

  const [autoPromoterUserData, setAutoPromoterUserData] = useState<BatchPromoter[]>([]);
  const [autoFilteredPromoterUserValue, setAutoFilteredPromoterUserValue] = useState<BatchPromoter[]>([]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isSpecialBatch, setisSpecialBatch] = useState<boolean>(false);
  const [specialTrekTypes, setSpecialTrekTypes] = useState<ISpecialTrekTypeModel[]>([]);
  const [specialTrekFilteredValues, setSpecialTrekFilteredValues] = useState<ISpecialTrekTypeModel[]>([]);
  const [selectedSeasonData, setSelectedSeasonData] = useState<ITrekSeasons>();
  const [isSeasonsDataLoaded, setIsSeasonsDataLoaded] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const defaultValues = {
    privateValue: false,
    multiBatchEnabled: false,
    waitCapacity: 0,
    capacity: 0,
  };

  useEffect(() => {
    const trekService = TrekService;
    const lookupService = LookupService;
    trekService.getByLookupFormat({ fetchFamilyNamedTreks: false }).then((trekData) => {
      setAutoTrekValueData(trekData);
      lookupService.getUser('TREK_LEADER').then((trekLeaders) => {
        setAutoLeaderUserData(trekLeaders);
        lookupService.getBatchPromoters().then((transportUsers) => {
          setAutoPromoterUserData(transportUsers);
          lookupService.getSpecialTrekTypes().then((specialTreks) => {
            setSpecialTrekTypes(specialTreks);
            if (!isAddMode) getAndBindData(trekData, trekLeaders, transportUsers, specialTreks);
            else setDataLoaded(true);
          });
        });
      });
    });
  }, []);

  const getAndBindData = (
    trekData: ITrekLookupModel[],
    trekLeaders: IUserLookupModel[],
    transportUsers: BatchPromoter[],
    specialTreks: ISpecialTrekTypeModel[],
  ) => {
    // get user and set form fields
    BatchService.getById(id).then((batchApiData: IBatchApiModel) => {
      const selectedTrekObject = trekData?.find((x) => x.trekId == batchApiData.trekId);
      const selectedSpecialTrekObject = specialTreks.find((x) => x.id == batchApiData.specialTrekType);
      setSelectedTrekValue(selectedTrekObject ? selectedTrekObject : null);
      setSelectedSpecialTrekValue(selectedSpecialTrekObject ? selectedSpecialTrekObject : null);

      const selectedLeaders = filteredUsers(trekLeaders, batchApiData.trekLeaderIds);
      const selectedPromoter = transportUsers.find((x) => x.id == batchApiData.promoterId);
      const trek = trekData?.find((x) => x.trekId == batchApiData.trekId);
      bindSeasons(batchApiData.trekId, 2, batchApiData.seasonId);
      if (batchApiData.specialTrekType) {
        setisSpecialBatch(true);
      }
      setValue('trekId', batchApiData.trekId);
      setValue('specialTrek', batchApiData.specialTrekType);
      setValue('promoter', selectedPromoter);
      setValue('trekLeaders', selectedLeaders);

      setValue('startDate', new Date(batchApiData.startDate));
      setValue('endDate', new Date(batchApiData.endDate));

      setValue('capacity', batchApiData.capacity);
      setValue('waitCapacity', batchApiData.waitCapacity);
      setValue('privateVal', batchApiData.privateBatch);
      setValue('whatsappGroupLink', batchApiData.whatsappGroupLink);
      setValue('preTrekBriefingLink', batchApiData.preTrekBriefingLink);
      setDataLoaded(true);
    });
  };

  const filteredUsers = (userData: IUserLookupModel[], filterIds: number[]) => {
    const items: IUserLookupModel[] = [];
    filterIds?.map((x) => {
      const val = userData?.find((y) => y.id == x);
      if (val != undefined) items.push(val);
    });
    return items;
  };

  const autoSearchTrek = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...autoTrekValueData]);
    } else {
      let avValue: any = autoTrekValueData;
      setAutoFilteredValue(
        avValue.filter((location: any) => {
          return location.name.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchTrekLeaders = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoLeaderTransportUserValue([...autoLeaderUserData]);
    } else {
      setAutoLeaderTransportUserValue(
        autoLeaderUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSeasons = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setSeasonsFilteredValue([...autoSeasonsValueData]);
    } else {
      setSeasonsFilteredValue(
        autoSeasonsValueData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const specialTreksProcessedValues = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setSpecialTrekFilteredValues([...specialTrekTypes]);
    } else {
      setSpecialTrekFilteredValues(
        specialTrekTypes.filter((trekType: ISpecialTrekTypeModel) => {
          return trekType.name.toLocaleLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchPromoters = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredPromoterUserValue([...autoPromoterUserData]);
    } else {
      setAutoFilteredPromoterUserValue(
        autoPromoterUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  // @ts-ignore
  const validationSchema = Yup.object({
    trekId: Yup.number().required('Trek is required'),
    seasonId: Yup.object().required('Season is required'),
    waitCapacity: Yup.number().required('WaitCapacity is required'),
    capacity: Yup.number().required('Capacity is required').moreThan(0),
    startDate: Yup.date()
      .required('Start Date  is required')
      .test('compare-start-dates', "*Batch's Start Date* should be AFTER *Season's Start Date*", (value?: Date) => {
        if (selectedSeasonData?.startDate && value) return compareDates(value, selectedSeasonData.startDate);
        else return false;
      }),
    endDate: Yup.date()
      .required('End Date  is required')
      .test('enddate-comparer', 'End date should greater than start-date', (value?: Date) => {
        let startDate: Date = getValues('startDate');
        if (value && startDate) return compareDates(value, startDate, true);
        else return false;
      })
      .test('compare-end-dates', "*Batch's End Date* should be BEFORE *Season's End Date*", (value?: Date) => {
        if (selectedSeasonData?.endDate && value) return compareDates(selectedSeasonData.endDate, value);
        else return false;
      }),
  });

  const { register, handleSubmit, reset, setValue, control, getValues, errors, formState } = useForm<IBatchModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: defaultValues,
  });

  const onSubmit = (data: IBatchModel) => {
    const tst: any = toast?.current;
    let errorCase = false,
      errorMsg = '';
    if (isSpecialBatch) {
      if (!data.specialTrek) {
        errorCase = true;
        errorMsg = 'Special Batch is Checked, Need to provide the value for Special Trek Type';
      }
    }
    if (errorCase) {
      tst.show({
        severity: 'error',
        summary: errorMsg,
      });
      return;
    }
    setSaveInProgress(true);
    return isAddMode ? createBatch(data) : updateBatch(id, data);
  };

  const createBatch = (data: IBatchModel) => {
    return BatchService.create(data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Group Created Successfully', detail: '' });
        window.close();
      })
      .catch((res) => {
        setSaveInProgress(false);
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const updateBatch = (id: number, data: IBatchModel) => {
    return BatchService.update(id, data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Group updated Successfully', detail: '' });
        window.close();
      })
      .catch((res) => {
        setSaveInProgress(false);
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const handleCancelClick = () => {
    window.close();
    //isAddMode ? history.push('/batches') : history.push('/batches');
  };

  const bindSeasons = (trekId: number, flag: number = 1, seasonId?: number) => {
    setValue('seasonId', null);
    setIsSeasonsDataLoaded(false);
    setSeasonsFilteredValue([]);
    setAutoSeasonsValueData([]);
    if (trekId > 0) {
      const lookupService = LookupService;
      lookupService.getTrekSeasons(trekId).then((seasonsData) => {
        const dt: ITrekSeasons[] = [];
        seasonsData.map((x) => {
          let formattedText = formattedDateText(x.startDate, x.endDate).toString();
          const trekName = x.specialTrekType
            ? specialTrekTypes.find((spTrek) => spTrek.id == x.specialTrekType)?.name
            : 'REGULAR';
          const trekFees = x.seasonFees?.filter((fee) => fee.feeType === 'TREK')[0].amount || 0;
          formattedText += ` (${trekName} - ₹${trekFees})`;
          dt.push({
            id: x.id,
            formattedText,
            trekId: x.trekId,
            startDate: x.startDate,
            endDate: x.endDate,
            trek: x.trek,
          });
        });
        if (!dt.length) {
          const tst: any = toast?.current;
          tst.show({ severity: 'warn', summary: 'No Season found for the Selected Trek', detail: '' });
        }
        setAutoSeasonsValueData(dt);
        if (flag == 2) {
          const selectedSeason = dt.find((x) => x.id == seasonId);
          setSelectedSeasonData(selectedSeason);
          setValue('seasonId', selectedSeason);
        }
        setIsSeasonsDataLoaded(true);
      });
    }
  };

  // @ts-ignore
  const formatYmd = (dt: Date) => dt.getDate() + '-' + months[dt.getMonth()] + '-' + dt.getFullYear();
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const formattedDateText = (startDate: Date, endDate: Date) => {
    const date = new Date(startDate);
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear;
    return `${formatYmd(new Date(startDate))} - ${formatYmd(new Date(endDate))}`;
  };

  const updateSpecialTrekData = (specialTrek: ISpecialTrekTypeModel | null = null) => {
    setSelectedSpecialTrekValue(!!specialTrek ? specialTrek : null);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast}></Toast>
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{isAddMode ? 'Add Group' : 'Edit Group'}</div>
          </div>
          <div>
            <Link to="/batches">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }}></i>
            </Link>
          </div>
        </div>
        {!dataLoaded && <Overlay text="Loading..." icon="pi-spinner" iconSpin />}
        <div className="p-grid">
          <div className="p-col-12 p-lg-8 p-md-12">
            <div className="card p-fluid">
              <h4>Group details</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-3">
                      <div className="p-mb-2 required">
                        <label htmlFor="trekId">Trek:</label>
                      </div>
                      <div>
                        <Controller
                          name="trekId"
                          control={control}
                          render={({ onChange, value }) => (
                            <AutoComplete
                              placeholder="Search"
                              autoFocus
                              dropdown
                              forceSelection
                              value={selectedTrekValue}
                              onChange={(e) => {
                                setSelectedTrekValue(e.value);
                                bindSeasons(e.value?.trekId);
                                onChange(e.value?.trekId);
                              }}
                              suggestions={autoFilteredValue}
                              disabled={!isAddMode}
                              completeMethod={autoSearchTrek}
                              field="name"
                            ></AutoComplete>
                          )}
                        />
                        {errors.trekId && (
                          <span className="p-error">
                            <p>Error:{errors.trekId?.message}</p>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="p-mb-3">
                      <div className="p-mb-3">
                        <Checkbox
                          checked={isSpecialBatch}
                          onChange={() => {
                            setisSpecialBatch((prevState) => !prevState);
                            updateSpecialTrekData();
                          }}
                          disabled={!isAddMode}
                        />
                        <label className="mx-2">
                          <b>Create Special Batch</b>
                        </label>
                      </div>
                      {isSpecialBatch && (
                        <div className="p-mb-3">
                          <div className="p-mb-2">
                            <label htmlFor="specialTrek">Special Trek Type:</label>
                          </div>
                          <div>
                            <Controller
                              name="specialTrek"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  value={selectedSpecialTrekValue}
                                  onChange={(e) => {
                                    onChange(e.value?.id);
                                    updateSpecialTrekData(e.value);
                                  }}
                                  suggestions={specialTrekFilteredValues}
                                  completeMethod={specialTreksProcessedValues}
                                  disabled={!isAddMode}
                                  field="name"
                                ></AutoComplete>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-grid">
                      <div className="p-col-12 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="seasonId">Seasons:</label>
                          </div>
                          <div>
                            <Controller
                              name="seasonId"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder={`${selectedTrekValue && !isSeasonsDataLoaded ? 'Loading...' : 'Search'}`}
                                  dropdown
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                    setSelectedSeasonData(e.value);
                                  }}
                                  suggestions={autoSeasonsFilteredValue}
                                  disabled={!isAddMode || !autoSeasonsValueData.length}
                                  completeMethod={autoSearchSeasons}
                                  field="formattedText"
                                ></AutoComplete>
                              )}
                            />
                            {selectedTrekValue && isSeasonsDataLoaded && !autoSeasonsValueData.length && (
                              <span className="p-error">
                                <p>No Season Found for the Selected Trek</p>
                              </span>
                            )}
                            {errors.seasonId && (
                              <span className="p-error">
                                <p>Error:Season required</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="name">Start Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="startDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                ></Calendar>
                              )}
                            />
                            {errors.startDate && (
                              <span className="p-error">
                                <p>Error:{errors.startDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="endDate">End Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="endDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                ></Calendar>
                              )}
                            />
                            {errors.endDate && (
                              <span className="p-error">
                                <p>Error:{errors.endDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2 required">
                            <label htmlFor="capacity">Capacity :</label>
                          </div>
                          <div>
                            <Controller
                              name="capacity"
                              control={control}
                              defaultValue="0"
                              render={({ onChange, value }) => (
                                <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                              )}
                            />
                            {errors.capacity && (
                              <span className="p-error">
                                <p>Error:{errors.capacity?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2 required">
                            <label htmlFor="waitCapacity">Wait Capacity :</label>
                          </div>
                          <div>
                            <Controller
                              name="waitCapacity"
                              control={control}
                              defaultValue="0"
                              render={({ onChange, value }) => (
                                <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                              )}
                            />
                            {errors.waitCapacity && (
                              <span className="p-error">
                                <p>Error:{errors.waitCapacity?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="name">Trek leader (s):</label>
                          </div>
                          <div>
                            <Controller
                              name="trekLeaders"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  multiple
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                  suggestions={autoFilteredLeaderUserValue}
                                  completeMethod={autoSearchTrekLeaders}
                                  field="firstName"
                                ></AutoComplete>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="promoter">Promoter:</label>
                          </div>
                          <div>
                            <Controller
                              name="promoter"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                  suggestions={autoFilteredPromoterUserValue}
                                  completeMethod={autoSearchPromoters}
                                  field="name"
                                ></AutoComplete>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="description">Description:</label>
                          </div>
                          <div>
                            <Controller
                              name="description"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="whatsappGroupLink">WhatsApp group link:</label>
                          </div>
                          <div>
                            <Controller
                              name="whatsappGroupLink"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="preTrekBriefingLink">Pre-trek briefing link:</label>
                          </div>
                          <div>
                            <Controller
                              name="preTrekBriefingLink"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex p-mt-2">
                <div className="flex-grow-1"></div>
                <div className="p-mx-2">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-warning p-button-lg"
                    onClick={handleCancelClick}
                  />
                </div>
                <div className="">
                  <Button
                    type="submit"
                    label="Submit"
                    icon="pi pi-check"
                    className="p-button-lg p-button-secondary"
                    disabled={saveInProgress}
                  >
                    {saveInProgress && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withLoader(withRouter(BatchDetail));
//withRouter(LocationDetail);
