/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Link, useHistory } from 'react-router-dom';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import BatchService from '../../api/BatchService';
import batchService from '../../api/BatchService';
import { IBatchListModel, IBatchPageble, IBookingBatch, ITrekLookupModel } from '../../models';
import './BatchList.scss';
import withLoader from '../../helpers/withloader';
import Moment from 'react-moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { Calendar } from 'primereact/calendar';
import { IBatchFilterCriteria } from './BatchFilterCriteria';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import BookingService from '../../api/BookingService';
import { Dialog } from 'primereact/dialog';
import { IBatchApiModel } from '../../models/Batch/BatchApiModel';
import { IBatchFilter } from '../../models/Batch/IBatchFilter';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';
import { ColumnProps } from 'primereact/column';

const BatchList: React.FC = () => {
  const history = useHistory();
  const [batchData, setBatchData] = useState<IBatchListModel[]>();
  const [batchAuditData, setBatchAuditData] = useState<IBatchApiModel[]>();
  const [selectedCustomers1, setSelectedCustomers1] = useState<IBatchListModel>();
  const [globalFilter1] = useState('');
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);
  const [sortOrderType, setSortOrderType] = useState<DataTableSortOrderType>(1);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: undefined,
    sortOrder: sortOrderType,
    filters: undefined,
  });

  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [autoSpecialTrekValueData, setAutoSpecialTrekValueData] = useState<ISpecialTrekTypeModel[]>([]);
  const [isShown, setIsShown] = useState(false);

  const [batchFilter, setBatchFilter] = useState<IBatchFilter>();
  const [autoBatchValueData, setAutoBatchValueData] = useState<IBookingBatch[]>([]);

  useEffect(() => {
    const lookupService = LookupService;
    const trekService = TrekService;
    lookupService.getSpecialTrekTypes().then((specialTrekData) => {
      setAutoSpecialTrekValueData(specialTrekData);
      trekService.getByLookupFormat().then((trekData) => {
        setAutoTrekValueData(trekData);
        setLoading(false);
        setIsShown(true);
      });
    });
    fetchData(0, 10, batchFilter);
  }, []);

  const gridTableHeader = <div className="table-header">All Groups</div>;

  const bodyAuditTemplate = (data: any, props: any) => {
    return <>{data[props.field].toString()}</>;
  };

  const modifiedTemplate = (data: any, props: any) => {
    if (batchAuditData) {
      const batchCurrentData: any =
        props.rowIndex === batchAuditData.length - 1
          ? batchAuditData[props.rowIndex]
          : batchAuditData[props.rowIndex + 1];
      if (data[props.field] === batchCurrentData[props.field]) {
        return <>{data[props.field]}</>;
      } else {
        return <span className="status-danger">{data[props.field]}</span>;
      }
    } else {
      return <></>;
    }
  };

  const bodyTemplate = (data: any, props: any) => {
    if (props.field === 'privateBatch') {
    }

    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field] != null ? data[props.field].toString() : null}
      </>
    );
  };

  const specialTrekTemplate = (data: IBatchListModel, props: ColumnProps) => {
    if (!!props.field && props.field in data) {
      let specialTrekId = data[props.field];
      if (specialTrekId) {
        let specialTrek = autoSpecialTrekValueData.find((trek) => trek.id == specialTrekId);
        if (!!specialTrek) return <span className="status-dark">{specialTrek.name}</span>;
      }
    }
  };

  const dateFieldTemplate = (data: any, props: any) => {
    const dateToFormat = data[props.field];
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        <Moment format="DD-MMM-YY" date={dateToFormat} />
      </>
    );
  };

  const dateFieldAuditTemplate = (data: any, props: any) => {
    const dateToFormat = data[props.field];
    return (
      <>
        <Moment format="DD-MMM-YY" date={dateToFormat} />
      </>
    );
  };

  const addNewSeason = () => {
    window.open('#/batches/add', '_blank');
  };

  const addMultiBatch = () => {
    history.push('/batches/multi-batch');
  };

  const moveWaitingListToActive = () => {
    setLoading(true);
    BookingService.refreshWaitingList()
      .then((res) => {
        setLoading(false);
        fetchData(lazyParams.page, lazyParams.rows, batchFilter);
      })

      .catch(function (error) {
        setLoading(false);
      });
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="d-flex align-items-center">
        <div>
          <Button
            label=""
            icon="pi pi-camera"
            className="p-button-light p-icon-btn-custom"
            onClick={(e) => {
              onLogView(data.id);
            }}
          />
        </div>
        <div className="p-px-2">
          <Link to={`/batches/view/${data.id}`} target="_blank">
            <i className="pi pi-eye icon-success-color" color="secondary"></i>
          </Link>
        </div>
        <div>
          <Link to={`/batches/edit/${data.id}`} target="_blank">
            <i className="pi pi-pencil icon-info-color" color="secondary"></i>
          </Link>
        </div>
        <div className="p-px-2">
          <Button
            label=""
            icon="pi pi-trash"
            className="p-button-danger p-icon-btn-custom"
            onClick={(e) => {
              onDelete(data.id);
            }}
          />
        </div>
        <div className="p-px-0">
          <Button
            label=""
            icon="pi pi-ticket"
            disabled={data.batchState === 'CLOSED'}
            className={data.bookingsClosed ? 'p-button-info p-icon-btn-custom' : 'p-button-warning p-icon-btn-custom'}
            onClick={(e) => {
              onBookingStatusChange(data);
            }}
            tooltip={data.bookingsClosed ? 'Revert to previous state' : 'Mark group full'}
            tooltipOptions={{ position: 'top' }}
          />
        </div>
      </div>
    );
  };

  const onPage = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    fetchData(_lazyParams.page, _lazyParams.rows, batchFilter, _lazyParams.sortField, _lazyParams.sortOrder == 1);
  };

  const onSort = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
    fetchData(_lazyParams.page, _lazyParams.rows, batchFilter, _lazyParams.sortField, _lazyParams.sortOrder == 1);
  };

  const onFilter = (event: any) => {
    let _lazyParams = { ...lazyParams, ...event };
    _lazyParams['first'] = 0;
    setLazyParams(_lazyParams);
    fetchData(_lazyParams.page, _lazyParams.rows, batchFilter);
  };

  const fetchData = (
    page: number,
    rows: number,
    batchFilter?: IBatchFilter,
    sortField?: string,
    descendingSorting?: boolean,
  ) => {
    const batchService = BatchService;
    setLoading(true);
    batchService
      .get(page, rows, batchFilter, sortField, descendingSorting)
      .then((res) => {
        const dt: IBatchPageble<IBatchListModel> = {
          totalRecords: res.headers['x-number-of-batches-found'],
          currentPage: page,
          rowsPerPage: rows,
          data: (res.data as unknown) as Array<IBatchListModel>,
        };
        if (dt.data != undefined) setBatchData(dt.data);

        setLoading(false);
        setTotalRecords(dt.totalRecords);
      })

      .catch(function (error) {
        setLoading(false);
      });
  };

  const onDelete = (id: number) => {
    const batchService = BatchService;

    confirmDialog({
      message: 'Are you sure you want to delete the batch?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        batchService.deleteBatch(id).then(() => {
          const tst: any = toast?.current;
          tst.show({ severity: 'success', summary: ' Batch deleted successfully', detail: '' });

          fetchData(lazyParams.page, lazyParams.rows, batchFilter);
        });
      },
    });
  };

  const onLogView = (batchId: number) => {
    batchService.fetchBatchAuditView(batchId).then((data) => {
      if (data && data.data && data.data.length > 0) {
        const batchAudits = data.data;
        setBatchAuditData(batchAudits);
        setDisplayLogs(true);
      }
    });
  };

  const onBookingStatusChange = (data: IBatchListModel) => {
    const message = data.bookingsClosed
      ? 'Are you sure you want to revert the batch to previous state?'
      : 'Are you sure you want to make the batch full?';
    const successMsg = data.bookingsClosed
      ? 'Group State Reverted back successfully'
      : 'Group State Marked Full successfully';
    const errorMsg = data.bookingsClosed ? 'Failed to revert back the batch state!' : 'Failed to make batch full!';
    confirmDialog({
      message: message,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        batchService
          .updateBookingStatus(data.id)
          .then(() => {
            const tst: any = toast?.current;
            tst.show({ severity: 'success', summary: successMsg, detail: '' });

            fetchData(lazyParams.page, lazyParams.rows, batchFilter);
          })
          .catch((error) => {
            const tst: any = toast?.current;
            tst.show({ severity: 'error', summary: errorMsg, detail: error.message });
          });
      },
    });
  };

  const onHide = () => {
    setDisplayLogs(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    errors,
    formState,
  } = useForm<IBatchFilterCriteria>({});

  let privateDropdownBatchStatus: any = [];
  privateDropdownBatchStatus.push({ name: 'Active', code: 'ACTIVE' });
  privateDropdownBatchStatus.push({ name: 'Full', code: 'FULL' });
  privateDropdownBatchStatus.push({ name: 'Waiting List', code: 'WAITING_LIST' });
  privateDropdownBatchStatus.push({ name: 'Closed', code: 'CLOSED' });

  let privateDropdownStatus: any = [];
  privateDropdownStatus.push({ name: 'True', code: 'true' });
  privateDropdownStatus.push({ name: 'False', code: 'false' });

  const bindBatches = (trekId: number, flag: number = 1, batchId?: number) => {
    setValue('batchId', null);
    setAutoBatchValueData([]);
    const dt: IBookingBatch[] = [];
    if (trekId > 0) {
      const lookupService = LookupService;
      lookupService.getBatchForBookings(trekId).then((batchData) => {
        batchData.map((x) => {
          dt.push({
            id: x.id,
            formattedText: formattedDateText(x.startDate, x.endDate).toString(),
            trekId: x.trekId,
            startDate: x.startDate,
            endDate: x.endDate,
          });
        });
        setAutoBatchValueData(dt);
      });
    }
  };

  const items = [{ label: 'Dashboard', url: '/dashboard' }, { label: 'Group' }];

  const home = { icon: 'pi pi-home', url: '/' };

  const formatYmd = (dt: Date) => dt.getDate() + '-' + months[dt.getMonth()] + '-' + dt.getFullYear();
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const formattedDateText = (startDate: Date, endDate: Date) => {
    const date = new Date(startDate);
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear;
    return `${formatYmd(new Date(startDate))} - ${formatYmd(new Date(endDate))}`;
  };

  const onSubmit = (data: any) => {
    let batchFilter: IBatchFilter = {
      batchStartDateFrom: undefined,
      batchStartDateTo: undefined,
      batchState: undefined,
      privateBatch: undefined,
      trekId: undefined,
      trekLeaderId: undefined,
      specialTrekType: undefined,
    };

    if (data.trek != null) batchFilter.trekId = data.trek;

    if (data.startDateFrom != null) {
      batchFilter.batchStartDateFrom = moment(data.startDateFrom).format('yyyy-MM-DD');
    }
    if (data.startDateTo != null) {
      batchFilter.batchStartDateTo = moment(data.startDateTo).format('yyyy-MM-DD');
    }

    if (data.privateStatus != null) batchFilter.privateBatch = data.privateStatus;

    if (data.batchStatus != null) batchFilter.batchState = data.batchStatus;

    if (data.specialTrek != null) batchFilter.specialTrekType = data.specialTrek;

    setBatchFilter(batchFilter);

    fetchData(0, lazyParams.rows, batchFilter);
  };
  return (
    <div className="p-grid table-demo">
      <Toast ref={toast} />
      <div className="p-col-12">
        <div className="align-items-center mb-2">
          <BreadCrumb model={items} home={home} />
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            <div className="display-1">Group</div>
          </div>
          <div className="p-m-3">
            Total Groups: <Badge value={totalRecords} severity="info"></Badge>
          </div>
          <div>
            <Button
              label="Move Waiting List To Active"
              onClick={moveWaitingListToActive}
              className="p-button-lg p-button-secondary p-button-dark"
              iconPos="right"
            />
          </div>{' '}
          &nbsp;
          <div>
            <Button
              label="Create Multi Groups"
              onClick={addMultiBatch}
              className="p-button-lg p-button-secondary p-button-dark"
              icon="pi pi-arrow-right"
              iconPos="right"
            />
          </div>{' '}
          &nbsp;
          <div>
            <Button
              label="Create New Group"
              onClick={addNewSeason}
              className="p-button-lg p-button-secondary p-button-dark"
              icon="pi pi-arrow-right"
              iconPos="right"
            />
          </div>
        </div>

        {isShown && (
          <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
            <div className="card">
              <div className="p-grid">
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Start Date From:</label>
                    </div>
                    <div>
                      <Controller
                        name="startDateFrom"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Start Date To:</label>
                    </div>
                    <div>
                      <Controller
                        name="startDateTo"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                          ></Calendar>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek Name:</label>
                    </div>
                    <div>
                      <Controller
                        name="trek"
                        control={control}
                        render={({ onChange, value }) => (
                          <Dropdown
                            optionLabel="name"
                            optionValue="trekId"
                            value={value}
                            options={autoTrekValueData}
                            onChange={(e) => {
                              onChange(e.value);
                              bindBatches(e.value);
                            }}
                            placeholder="Select a Trek"
                            filter
                            showClear
                            filterBy="name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div className="p-mb-2">
                    <label htmlFor="specialTrek">Special Trek:</label>
                  </div>
                  <div>
                    <Controller
                      name="specialTrek"
                      control={control}
                      render={({ onChange, value }) => (
                        <Dropdown
                          optionLabel="name"
                          optionValue="id"
                          value={value}
                          options={autoSpecialTrekValueData}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          placeholder="Select Special Trek"
                          filter
                          showClear
                          filterBy="name"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Private Group:</label>
                    </div>
                    <div>
                      <Controller
                        name="privateStatus"
                        control={control}
                        render={({ onChange, value }) => (
                          <Dropdown
                            optionLabel="name"
                            optionValue="code"
                            value={value}
                            options={privateDropdownStatus}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Select a private group"
                            filter
                            showClear
                            filterBy="name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-2">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Group Status:</label>
                    </div>
                    <div>
                      <Controller
                        name="batchStatus"
                        control={control}
                        render={({ onChange, value }) => (
                          <Dropdown
                            optionLabel="name"
                            optionValue="code"
                            value={value}
                            options={privateDropdownBatchStatus}
                            onChange={(e) => {
                              onChange(e.value);
                            }}
                            placeholder="Select a group status"
                            filter
                            showClear
                            filterBy="name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="flex-grow-1"></div>
              <div>
                <Button
                  type="submit"
                  label="Submit"
                  icon="pi pi-filter"
                  className="p-mx-2 p-button-lg p-button-secondary"
                  disabled={formState.isSubmitting}
                >
                  {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
                </Button>
              </div>
            </div>
          </form>
        )}
        <p></p>
        <div className="card">
          <DataTable
            scrollable
            style={{ width: '100%' }}
            value={batchData}
            lazy={true}
            paginator
            first={lazyParams.first}
            rowsPerPageOptions={[5, 10, 20, 50]}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onFilter={onFilter}
            filters={lazyParams.filters}
            loading={loading}
            className="p-datatable-customers"
            dataKey="id"
            rowHover
            selection={selectedCustomers1}
            onSelectionChange={(e: any) => setSelectedCustomers1(e.value)}
            globalFilter={globalFilter1}
            emptyMessage="No record(s) found."
            header={gridTableHeader}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> */}
            <Column field="trek" header="Name" headerStyle={{ width: '112px' }} sortable body={bodyTemplate}></Column>
            <Column
              field="startDate"
              header="Start Date"
              headerStyle={{ width: '100px' }}
              sortable
              body={dateFieldTemplate}
            ></Column>
            <Column
              field="endDate"
              header="End Date"
              headerStyle={{ width: '100px' }}
              sortable
              body={dateFieldTemplate}
            ></Column>
            <Column
              field="capacity"
              header="Capacity"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="specialTrekType"
              header="Special Trek"
              headerStyle={{ width: '100px' }}
              sortable
              body={specialTrekTemplate}
            ></Column>
            <Column
              field="numberOfConfirmedBooking"
              header="Confirmed"
              body={bodyTemplate}
              headerStyle={{ width: '100px' }}
            ></Column>
            <Column
              field="numberOfUnConfirmedBooking"
              header="Unconfirmed"
              body={bodyTemplate}
              headerStyle={{ width: '105px' }}
            ></Column>
            <Column
              field="numberOfActiveBooking"
              header="Active"
              body={bodyTemplate}
              headerStyle={{ width: '70px' }}
            ></Column>
            <Column
              field="availableSlots"
              header="Available slots"
              headerStyle={{ width: '70px' }}
              body={bodyTemplate}
            ></Column>
            <Column
              field="waitCapacity"
              header="WL Capacity"
              headerStyle={{ width: '90px' }}
              body={bodyTemplate}
            ></Column>
            <Column
              field="numberOfWaitingListBooking"
              header="WL Active"
              headerStyle={{ width: '50px' }}
              body={bodyTemplate}
            ></Column>
            <Column
              field="availableWaitingListSlots"
              header="WL Slots Available"
              headerStyle={{ width: '90px' }}
              body={bodyTemplate}
            ></Column>
            {/* <Column field="waitCapacity" header="Wait Capacity" headerStyle={{ width: '100px' }} sortable body={bodyTemplate}></Column> */}
            <Column
              field="privateBatch"
              header="Private"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="batchState"
              header="State"
              headerStyle={{ width: '100px' }}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              field="trekLeaders"
              header="Trek Leader"
              headerStyle={{ width: '100px' }}
              body={bodyTemplate}
            ></Column>
            <Column field="promoter" header="Promoter" headerStyle={{ width: '100px' }} body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '160px', textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
              body={actionTemplate}
              header="Action"
            ></Column>
          </DataTable>

          <div className="p-col-12">
            <Dialog
              header="Batch Audit"
              visible={displayLogs}
              style={{ width: '90vw', height: '100vw' }}
              onHide={onHide}
            >
              <div>
                <div className="card">
                  <DataTable value={batchAuditData}>
                    <Column field="state" header="State" body={modifiedTemplate}></Column>
                    <Column field="capacity" header="Capacity" body={modifiedTemplate}></Column>
                    <Column field="waitCapacity" header="Waiting List Capacity" body={modifiedTemplate}></Column>
                    <Column field="whatsappGroupLink" header="Whatsapp Link" body={modifiedTemplate}></Column>
                    <Column field="description" header="Description" body={modifiedTemplate}></Column>
                    <Column field="privateBatch" header="Private Batch" body={bodyAuditTemplate}></Column>
                    <Column field="revisionType" header="Action"></Column>
                    <Column field="modifiedBy" header="Modified By"></Column>
                    <Column field="modifiedAt" header="Modified At" body={dateFieldAuditTemplate}></Column>
                  </DataTable>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withLoader(BatchList);
