/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import withLoader from '../../helpers/withloader';
import { ISeasonDetailFormModel, ISeasonsApiModel, ITrekLookupModel, IUserLookupModel } from '../../models/index';
import SeasonService from '../../api/SeasonService';
import { AutoComplete } from 'primereact/autocomplete';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { Checkbox } from 'primereact/checkbox';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';

const SeasonDetail: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);
  const isAddMode = !id;
  const [location, setLocation] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const toast = useRef(null);

  const [insuranceOpted, setInsuranceOpted] = useState<boolean>(true);
  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [selectedTrekValue, setSelectedTrekValue] = useState<ITrekLookupModel | null>(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState<ITrekLookupModel[]>([]);

  const [autoTransportUserData, setAutoTransportUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredTransportUserValue, setAutoFilteredTransportUserValue] = useState<IUserLookupModel[]>([]);
  const [selectedTransportUserAutoValue, setSelectedTransportUserAutoValue] = useState<IUserLookupModel[]>();

  const [autoSlopeUserData, setAutoSlopeUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredSlopeUserValue, setAutoFilteredSlopeUserValue] = useState<IUserLookupModel[]>([]);
  const [selectedSlopeUserAutoValue, setSelectedSlopeUserAutoValue] = useState<IUserLookupModel[]>();

  const [autoSpecialTrekData, setAutoSpecialTrekData] = useState<ISpecialTrekTypeModel[]>([]);
  const [autoFilteredSpecialTrekValue, setAutoFilteredSpecialTrekValue] = useState<ISpecialTrekTypeModel[]>([]);
  const [selectedSpecialTrekAutoValue, setSelectedSpecialTrekAutoValue] = useState<ISpecialTrekTypeModel[]>();

  const defaultValues = {
    trekFee: 0,
    insuranceOpted: true,
    backPackOffloading: 0,
    tentSharing: 0,
    offlineBackPack: 0,
  };

  useEffect(() => {
    const trekService = TrekService;
    const lookupService = LookupService;
    trekService.getByLookupFormat().then((trekData) => {
      setAutoTrekValueData(trekData);
      lookupService.getUser('TRANSPORT_COORDINATOR').then((transUserdata) => {
        setAutoTransportUserData(transUserdata);
        lookupService.getUser('SLOPE_MANAGER').then((slopeUserData) => {
          setAutoSlopeUserData(slopeUserData);
          lookupService.getSpecialTrekTypes().then((specialTrekData) => {
            setAutoSpecialTrekData(specialTrekData);
            if (!isAddMode) {
              getAndBindData(trekData, transUserdata, slopeUserData, specialTrekData);
            }
          });
        });
      });
    });
  }, []);

  const getAndBindData = (
    trekData: ITrekLookupModel[],
    transPortUserData: IUserLookupModel[],
    slopeManagerUsersData: IUserLookupModel[],
    specialTrekTypesData: ISpecialTrekTypeModel[],
  ) => {
    // get user and set form fields
    SeasonService.getById(id).then((seasonData: ISeasonsApiModel) => {
      const selectedTrekObject = trekData?.find((x) => x.trekId == seasonData.trekId);
      setSelectedTrekValue(selectedTrekObject ? selectedTrekObject : null);

      const selectedTransUsers = filteredUsers(transPortUserData, seasonData.transportCoordinatorIds);
      setSelectedTransportUserAutoValue(selectedTransUsers ? selectedTransUsers : []);

      const selectedSlopeManagers = filteredUsers(slopeManagerUsersData, seasonData.slopeManagerIds);
      setSelectedSlopeUserAutoValue(selectedSlopeManagers ? selectedSlopeManagers : []);

      const selectedSpecialTreks = filteredSpecialTreks(specialTrekTypesData, seasonData.specialTrekTypes);
      setSelectedSpecialTrekAutoValue(selectedSpecialTreks ? selectedSpecialTreks : []);

      const selectedObject = trekData?.find((x) => x.trekId == seasonData.trekId);
      setSelectedTrekValue(selectedObject ? selectedObject : null);

      setValue('trekId', seasonData.trekId);
      setValue('startDate', new Date(seasonData.startDate));
      setValue('endDate', new Date(seasonData.endDate));
      setValue('trekFee', seasonData.seasonFees.find((x) => x.feeType == 'TREK')?.amount);
      setValue('backPackOffloading', seasonData.seasonFees.find((x) => x.feeType == 'BACKPACK_OFFLOADING')?.amount);
      setValue('tentSharing', seasonData.seasonFees.find((x) => x.feeType == 'TENT_SHARING')?.amount);
      setValue('insuranceOpted', seasonData.insuranceOpted);
      setValue(
        'offlineBackPack',
        seasonData.seasonFees.find((x) => x.feeType == 'OFFLINE_BACKPACK_OFFLOADING')?.amount,
      );
      setValue('transportCoordinatorIds', selectedTransUsers);
      setValue('slopeManagerIds', selectedSlopeManagers);
      setValue('specialTrekTypes', selectedSpecialTreks);
    });
  };

  const filteredUsers = (userData: IUserLookupModel[], filterIds: number[]) => {
    const items: IUserLookupModel[] = [];
    filterIds?.map((x) => {
      const val = userData?.find((y) => y.id == x);
      if (val != undefined) items.push(val);
    });
    return items;
  };

  const filteredSpecialTreks = (specialTrekData: ISpecialTrekTypeModel[], filterIds: number[]) => {
    let items: ISpecialTrekTypeModel[] = [];
    items = specialTrekData?.filter((obj) => filterIds.includes(obj.id));
    return items;
  };

  const autoSearchTrek = (event: any) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...autoTrekValueData]);
    } else {
      let avValue: any = autoTrekValueData;
      setAutoFilteredValue(
        avValue.filter((location: any) => {
          return location.name.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchTransportUsers = (event: any) => {
    if (!event.query.trim().length) {
      setAutoFilteredTransportUserValue([...autoTransportUserData]);
    } else {
      setAutoFilteredTransportUserValue(
        autoTransportUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSlopeUsers = (event: any) => {
    if (!event.query.trim().length) {
      setAutoFilteredSlopeUserValue([...autoSlopeUserData]);
    } else {
      setAutoFilteredSlopeUserValue(
        autoSlopeUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSpecialTreks = (event: any) => {
    if (event.query.trim().length) {
      setAutoFilteredSpecialTrekValue(
        autoSpecialTrekData.filter((trek: any) => {
          return trek?.name?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    } else {
      setAutoFilteredSpecialTrekValue([...autoSpecialTrekData]);
    }
  };

  // @ts-ignore
  const validationSchema = useMemo(
    () =>
      Yup.object({
        trekId: Yup.string().required('Trek is required'),
        startDate: Yup.date().required('Start Date  is required'),
        endDate: Yup.date()
          .required('End Date  is required')
          .test('enddate-comparer', 'End date should greater than start-date', function (value: any) {
            const startDt: Date = new Date(getValues('startDate'));
            const endDt: Date = new Date(value);
            const valid: boolean = endDt > startDt;
            return valid;
          }),
        trekFee: Yup.number().required('TrekFee  is required').min(0),
      }),
    [],
  );

  // functions to build form returned by useForm() hook
  // @ts-ignore
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    errors,
    formState,
  } = useForm<ISeasonDetailFormModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ISeasonDetailFormModel) => {
    return isAddMode ? createSeason(data) : updateSeason(id, data);
  };

  const createSeason = (data: ISeasonDetailFormModel) => {
    return SeasonService.create(data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Season Created Successfully', detail: '' });
        //setInput1Focus
        // alertService.success('User added', { keepAfterRouteChange: true });
        //reset();
        history.push('/season');
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const updateSeason = (id: number, data: ISeasonDetailFormModel) => {
    return SeasonService.update(id, data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Season updated Successfully', detail: '' });
        history.push('/season');
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const validationSummary = (
    <div className="validation-summary-error">
      <pre>
        {Object.keys(errors).length > 0 && (
          <div>
            Please fill missing/correct required field values in the following information :
            <ul>
              {Object.keys(errors).map((field) => (
                <li>{field} </li>
              ))}
            </ul>
          </div>
        )}
      </pre>
    </div>
  );

  const handleCancelClick = () => {
    isAddMode ? history.push('.') : history.push('..');
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast}></Toast>
        {/* <h4 className="p-mt-2">
        {' '}
        <Link to="/season">
        <i className="pi pi-arrow-left icon-dark-color" style={{ fontSize: '1rem' }}></i>
        </Link> &nbsp;&nbsp;
        Back
      </h4> */}
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{isAddMode ? 'Add Season' : 'Edit Season'}</div>
          </div>
          <div>
            <Link to="/season">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }}></i>
            </Link>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-12 p-lg-6 p-md-12">
            <div className="card p-fluid">
              <h4>Season details</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek:</label>
                    </div>
                    <div>
                      <Controller
                        name="trekId"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Search"
                            autoFocus
                            dropdown
                            forceSelection
                            value={selectedTrekValue}
                            onChange={(e) => {
                              setSelectedTrekValue(e.value);
                              onChange(e.value?.trekId);
                            }}
                            suggestions={autoFilteredValue}
                            disabled={!isAddMode}
                            completeMethod={autoSearchTrek}
                            field="name"
                          ></AutoComplete>
                        )}
                      />
                      {errors.trekId && (
                        <span className="p-error">
                          <p>Error:{errors.trekId?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-lg-6 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Start Date:</label>
                    </div>
                    <div>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                          ></Calendar>
                        )}
                      />
                      {errors.startDate && (
                        <span className="p-error">
                          <p>Error:{errors.startDate?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-lg-6 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">End Date:</label>
                    </div>
                    <div>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ onChange, value }) => (
                          <Calendar
                            dateFormat="dd/mm/yy"
                            value={value}
                            onChange={(e) => onChange(e.value)}
                            monthNavigator
                            yearNavigator
                            yearRange="2010:2030"
                          ></Calendar>
                        )}
                      />
                      {errors.endDate && (
                        <span className="p-error">
                          <p>Error:{errors.endDate?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Transport Coordinator(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="transportCoordinatorIds"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedTransportUserAutoValue}
                            onChange={(e) => {
                              setSelectedTransportUserAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredTransportUserValue}
                            completeMethod={autoSearchTransportUsers}
                            field="firstName"
                          ></AutoComplete>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Slope Managers(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="slopeManagerIds"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedSlopeUserAutoValue}
                            onChange={(e) => {
                              setSelectedSlopeUserAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredSlopeUserValue}
                            completeMethod={autoSearchSlopeUsers}
                            field="firstName"
                          ></AutoComplete>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Special Trek(s):</label>
                    </div>
                    <div>
                      <Controller
                        name="specialTrekTypes"
                        control={control}
                        render={({ onChange, value }) => (
                          <AutoComplete
                            placeholder="Search"
                            dropdown
                            multiple
                            value={selectedSpecialTrekAutoValue}
                            onChange={(e) => {
                              setSelectedSpecialTrekAutoValue(e.value);
                              onChange(e.value);
                            }}
                            suggestions={autoFilteredSpecialTrekValue}
                            completeMethod={autoSearchSpecialTreks}
                            field="name"
                          ></AutoComplete>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-field p-grid">
                  <label htmlFor="insuranceOpted" className="p-col-12 p-md-12">
                    Insurance Opted
                  </label>
                  <div className="p-col-12 p-md-10">
                    <Controller
                      name="insuranceOpted"
                      control={control}
                      render={({ onChange, value }) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            onChange(e.checked);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-lg-6 p-md-12">
            <div className="card p-fluid">
              <h4>Fees</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div className="required">
                    <div className="p-mb-2">
                      <label htmlFor="name">Trek:</label>
                    </div>
                    <div>
                      <Controller
                        name="trekFee"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                      {errors.trekFee && (
                        <span className="p-error">
                          <p>Error:{errors.trekFee?.message}</p>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Back pack offloading:</label>
                    </div>
                    <div>
                      <Controller
                        name="backPackOffloading"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">TentSharing:</label>
                    </div>
                    <div>
                      <Controller
                        name="tentSharing"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-2">
                      <label htmlFor="name">Offline Backpack offLoading:</label>
                    </div>
                    <div>
                      <Controller
                        name="offlineBackPack"
                        control={control}
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <InputNumber
                            value={value}
                            onValueChange={(e: any) => onChange(e.value)}
                            mode="decimal"
                            minFractionDigits={2}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-cpl-12 p-grid p-justify-end p-mt-2">
          <div className="">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-warning p-button-lg"
              onClick={handleCancelClick}
            />
          </div>
          <div className="">
            <Button
              type="submit"
              label="Submit"
              icon="pi pi-check"
              className="p-mx-2 p-button-lg p-button-secondary"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
            </Button>
          </div>
        </div>

        {/* <div className="p-grid">
        <div className="p-col-12 p-md-8">
          <div className="card p-fluid">
            {validationSummary}
            <div className="p-field p-grid required">
              <label htmlFor="name" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                Trek:
              </label>
              <div className="p-col-12 p-md-10">
                <Controller
                  name="trekId"
                  control={control}
                  render={({ onChange, value }) =>
                      <AutoComplete placeholder="Search" autoFocus dropdown forceSelection value={selectedTrekValue} onChange={(e) => {
                                                                       setSelectedTrekValue(e.value);
                                                                       onChange(e.value?.trekId)
                                                                   }}
                                                                   suggestions={autoFilteredValue}
                                                                    disabled={!isAddMode}
                                                                   completeMethod={autoSearchTrek} field="name">
                      </AutoComplete>}/>
                {errors.trekId && (
                  <span className="p-error">
                    <p>Error:{errors.trekId?.message}</p>
                  </span>
                )}
              </div>
            </div>

            <div className="p-field p-grid required">
                <label htmlFor="name" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                    Start Date:
                </label>
            <div className="p-col-12 p-md-10">
                <Controller
                    name="startDate"
                    control={control}
                    render={({ onChange, value }) =>
                        <Calendar dateFormat="dd/mm/yy"  value={value} onChange={(e) => onChange(e.value)}></Calendar>}
                       />
                {errors.startDate && (
                    <span className="p-error">
                    <p>Error:{errors.startDate?.message}</p>
                  </span>
                )}
            </div>
        </div>
              <div className="p-field p-grid required">
                  <label htmlFor="name" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0 ">
                      End Date:
                  </label>
                  <div className="p-col-12 p-md-10">
                      <Controller
                          name="endDate"
                          control={control}
                          render={({ onChange, value }) =>
                              <Calendar dateFormat="dd/mm/yy" value={value} onChange={(e) => onChange(e.value)}></Calendar>}
                      />
                      {errors.endDate && (
                          <span className="p-error">
                    <p>Error:{errors.endDate?.message}</p>
                  </span>
                      )}
                  </div>
              </div>
              <div className="p-field p-grid">
                  <label htmlFor="transportCoordinatorIds" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                      Transport Coordinator(s):
                  </label>
                  <div className="p-col-12 p-md-10">
                      <Controller
                          name="transportCoordinatorIds"
                          control={control}
                          render={({onChange, value}) =>
                              <AutoComplete placeholder="Search"
                                            dropdown multiple  value={selectedTransportUserAutoValue}
                                            onChange={(e) => {
                                                setSelectedTransportUserAutoValue(e.value);
                                                onChange(e.value)
                                            }}
                                            suggestions={autoFilteredTransportUserValue}
                                            completeMethod={autoSearchTransportUsers} field="firstName"></AutoComplete>}/>
                  </div>
              </div>
              <div className="p-field p-grid">
                  <label htmlFor="slopeManagerIds" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                      Slope Managers(s):
                  </label>
                  <div className="p-col-12 p-md-10">
                      <Controller
                          name="slopeManagerIds"
                          control={control}
                          render={({onChange, value}) =>
                              <AutoComplete placeholder="Search"
                                            dropdown multiple  value={selectedSlopeUserAutoValue}
                                            onChange={(e) => {
                                                setSelectedSlopeUserAutoValue(e.value);
                                                onChange(e.value)
                                            }}
                                            suggestions={autoFilteredSlopeUserValue}
                                            completeMethod={autoSearchSlopeUsers} field="firstName"></AutoComplete>}/>
                  </div>
              </div>
              <fieldset>
                  <legend>Fees:</legend>
                  <div className="p-field p-grid required">
                      <label htmlFor="description" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                          Trek:
                      </label>
                      <div className="p-col-12 p-md-10">
                          <Controller
                              name="trekFee"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) =>
                                  <InputNumber value={value} onValueChange={(e:any) => onChange(e.value)} mode="decimal" minFractionDigits={2} />}

                  />
                          {errors.trekFee && (
                              <span className="p-error">
                    <p>Error:{errors.trekFee?.message}</p>
                  </span>
                          )}

                      </div>
                  </div>
                  <div className="p-field p-grid ">
                      <label htmlFor="description" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                          Back pack offloading:
                      </label>
                      <div className="p-col-12 p-md-10">
                          <Controller
                              name="backPackOffloading"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) =>
                                  <InputNumber value={value} onValueChange={(e:any) => onChange(e.value)} mode="decimal" minFractionDigits={2} />}

                          />

                      </div>
                  </div>
                  <div className="p-field p-grid ">
                      <label htmlFor="description" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                          TentSharing:
                      </label>
                      <div className="p-col-12 p-md-10">
                          <Controller
                              name="tentSharing"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) =>
                                  <InputNumber value={value} onValueChange={(e:any) => onChange(e.value)} mode="decimal" minFractionDigits={2} />}

                          />

                      </div>
                  </div>
                  <div className="p-field p-grid ">
                      <label htmlFor="description" className="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
                          Offline Backpack offLoading:
                      </label>
                      <div className="p-col-12 p-md-10">
                          <Controller
                              name="offlineBackPack"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) =>
                                  <InputNumber value={value} onValueChange={(e:any) => onChange(e.value)} mode="decimal" minFractionDigits={2} />}
                          />
                      </div>
                  </div>
              </fieldset>
        </div>

          <div className="p-col-12 p-md-6">
            <Button
              type="submit"
              label="Submit"
              icon="pi pi-check"
              className="p-ml-2"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>}
            </Button>
            <Button
              label="Cancel"
              className="p-button-danger p-button-text p-mr-2 p-mb-2"
              onClick={handleCancelClick}
            />
          </div>
        </div>
      </div> */}
      </form>
    </>
  );
};

export default withLoader(withRouter(SeasonDetail));
//withRouter(LocationDetail);
