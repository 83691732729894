/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import BookingService from '../../api/BookingService';
import { IBookingComments, IParticipant } from '../../models';
import './Booking_list.scss';
import withLoader from '../../helpers/withloader';
import { Toast } from 'primereact/toast';
import BookingParticipantService from '../../api/BookingParticipantService';
import { Button } from 'primereact/button';
import Booking_Comments from './Booking_Comments';
import { Image } from 'primereact/image';
import {
  BookingParticipantState,
  ParticipantBackpackOffloadingState,
} from '../../models/Booking/BookingParticipantsList';
import { confirmDialog } from 'primereact/confirmdialog';
import Overlay from '../common/Overlay';
import ParticipantActionDialog, { ParticipantActionType } from '../common/ParticipantActionDialog';

const ParticipantView: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const participantId: number = parseInt(params.participantId, 10);
  const bookingId: number = parseInt(params.bookingId, 10);

  const [bookingParticipants, setBookingParticipants] = useState<IParticipant>();
  const toast = useRef(null);
  const [userOwnerId, setUserOwnerId] = useState(0);
  const [userIdFront, setUserIdFront] = useState(undefined);
  const [userIdBack, setUserIdBack] = useState(undefined);
  const [fitnessDocument, setFitnessDocument] = useState(undefined);
  const [displayDisapproveModal, setDisplayDisapproveModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const bookingService = BookingService;
    bookingService.getParticipantById(participantId).then((data: IParticipant) => {
      setBookingParticipants(data);
      fetchParticipantDocument();
      setUserOwnerId(data.userId);
    });
  }, []);

  const fetchParticipantDocument = () => {
    BookingService.getParticipantDocument(participantId, 'ID_PROOF_FRONT')
      .then((data) => setUserIdFront(data))
      .catch((error) => console.log(error));

    BookingService.getParticipantDocument(participantId, 'ID_PROOF_BACK')
      .then((data) => setUserIdBack(data))
      .catch((error) => console.log(error));

    BookingService.getParticipantDocument(participantId, 'FITNESS_APPROVAL')
      .then((data) => setFitnessDocument(data))
      .catch((error) => console.log(error));
  };

  const cancelParticipantBackpackOffloading = () => {
    BookingParticipantService.cancelParticipantBackpackOffloading(bookingId, participantId)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({
          severity: 'success',
          summary: 'Participant Backpack Offloading cancelled successfully',
          detail: '',
        });
        const bookingService = BookingService;
        bookingService
          .getParticipantById(participantId)
          .then((data: IParticipant) => {
            setBookingParticipants(data);
          })
          .catch(() => {
            window.location.reload();
          });
      })
      .catch((res) => {
        const tst: any = toast?.current;
        tst.show({
          severity: 'error',
          summary: 'Participant Backpack Offloading cancellation failed.',
          detail: '',
        });
      });
  };

  const approveParticipant = () => {
    setIsSubmitting(true);
    BookingParticipantService.approveBooking(participantId)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Participant Approved Successfully', detail: '' });
        window.close();
      })
      .catch((res) => {
        const tst: any = toast?.current;
        if (res.response.data.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const confirmDialogForBackpackOffloadingCancellation = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel backpack offloading for this participant?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        cancelParticipantBackpackOffloading();
      },
    });
  };

  const getUserCommentsDisplay = useCallback(() => {
    const commentsData: IBookingComments[] = [];
    if (bookingParticipants?.userDetailsForDisplay) {
      let { firstName, lastName, comment } = bookingParticipants.userDetailsForDisplay;
      let displayName = firstName + ' ' + lastName;
      if (comment) commentsData.push({ ...comment, displayName });
    }
    return commentsData;
  }, [bookingParticipants]);

  const renderParticipantsView = () => {
    if (bookingParticipants) {
      if (!bookingParticipants.userDetailsForDisplay) {
        return <Overlay text="Data is Inaccurate, please contact Tech Team." icon="pi-ban" />;
      }
      return (
        <>
          <div className="p-grid table-demo">
            <div className="p-col-12">
              <div className="d-flex align-items-center mb-2">
                <div className="flex-grow-1">
                  <div className="display-1">Participant {bookingParticipants.userDetailsForDisplay.firstName}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-header">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">&nbsp;</div>
              {bookingParticipants.backpackOffloadingState === ParticipantBackpackOffloadingState.PAID ||
              bookingParticipants.backpackOffloadingState === ParticipantBackpackOffloadingState.INITIATED ? (
                <Button
                  label="Cancel Backpack Offloading"
                  onClick={confirmDialogForBackpackOffloadingCancellation}
                  className="p-button-success"
                  style={{ marginRight: '5px' }}
                />
              ) : null}
              {bookingParticipants.bookingParticipantState !== BookingParticipantState.APPROVED ? (
                <Button
                  label="Approve Participant"
                  onClick={approveParticipant}
                  className="p-button-success"
                  disabled={isSubmitting}
                />
              ) : (
                <Button
                  label="Disapprove Participant"
                  onClick={() => setDisplayDisapproveModal(true)}
                  className="p-button-danger"
                  disabled={isSubmitting}
                />
              )}
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-4">
              <div className="card">
                <div className="p-grid">
                  <div className="p-col-6">Participant Name</div>
                  <div className="p-col-6">
                    {bookingParticipants.userDetailsForDisplay.firstName +
                      ' ' +
                      bookingParticipants.userDetailsForDisplay.lastName}
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6">Trek Name</div>
                  <div className="p-col-6">Test</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6">Emergency Details</div>
                  <div className="p-col-6">Test</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6">Fitness Regime</div>
                  <div className="p-col-6">{bookingParticipants.fitnessRegime}</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6">High Altitude Exp</div>
                  <div className="p-col-6">{bookingParticipants.highAltitudeTrekExperience}</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6"> Pick Up Details</div>
                  <div className="p-col-6">{bookingParticipants.pickupLocation}</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6"> Drop Details</div>
                  <div className="p-col-6">{bookingParticipants.dropOffLocation}</div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6"> Backpack offloading status</div>
                  <div className="p-col-6">{bookingParticipants.backpackOffloadingState}</div>
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-12">
                  <div className="card">
                    <div className="p-col-6">
                      <div>Fitness Record : </div>
                      <div>
                        {fitnessDocument && (
                          <Image
                            src={URL.createObjectURL(fitnessDocument)}
                            alt="Image"
                            className="id-card-img"
                            width="250"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-8">
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="card">
                    <div className="p-col-6">
                      <div>Id Card Front : </div>
                      <div>
                        {userIdFront && (
                          <Image
                            src={URL.createObjectURL(userIdFront)}
                            alt="Image"
                            className="id-card-img"
                            width="250"
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-col-6">
                      <div>Id Card Back : </div>
                      <div>
                        {userIdBack && (
                          <Image
                            src={URL.createObjectURL(userIdBack)}
                            alt="Image"
                            className="id-card-img"
                            width="250"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="card">
                    <div className="p-col-12 p-lg-12 p-md-12">
                      <div className="card p-fluid">
                        <fieldset>
                          <legend> Comments:</legend>
                          <Booking_Comments
                            mode={true}
                            bookingId={bookingId}
                            ownerId={userOwnerId}
                            commentType={'PARTICIPANT'}
                            participantId={participantId}
                            userComments={getUserCommentsDisplay}
                          />
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ParticipantActionDialog
            actionType={ParticipantActionType.Disapprove}
            displayModal={displayDisapproveModal}
            onHide={() => setDisplayDisapproveModal(false)}
            isSubmitting={isSubmitting}
            selectedParticipantId={participantId}
            postCallback={() => window.close()}
            setLoader={setIsSubmitting}
          />
        </>
      );
    } else {
      return <Overlay text="Loading..." icon="pi-spinner" iconSpin />;
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {renderParticipantsView()}
    </div>
  );
};

export default withLoader(ParticipantView);
