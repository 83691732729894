/* eslint-disable  */
import axios, { AxiosRequestConfig } from 'axios';
import { IBookingBatch, IUserLookupModel } from '../models';
import { ITrekSeasons } from '../models/Trek/TrekSeasons';
import { BatchPromoter } from '../models/Batch/BatchPromoterLookupModel';
import { ITags } from '../models/Reviews/Tags';
import { VoucherType } from '../models/Voucher/VoucherType';
import { getHeaderWithBearerToken } from '../helpers';
import { ISpecialTrekTypeModel } from '../models/Trek/SpecialTrekModel';
import { ICancellationReasonModel } from '../models/Trek/CancellationReasonModel';
import { SearchType } from '../components/common/AutoCompleteSearchBox';

export const lookupBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/lookups`;

interface AxiosRequestConfigWithSignal extends AxiosRequestConfig {
  signal?: AbortSignal;
}

class LookupService {
  lookupGetTagurl = `${lookupBaseApi}/tags`;

  setAxiosConfig(signal: AbortSignal | undefined): AxiosRequestConfigWithSignal {
    return {
      headers: getHeaderWithBearerToken(),
      signal: signal,
    };
  }

  getAxiosInstance = () => {
    return axios;
  };

  getUserById = (userId: number) => {
    const url = `${lookupBaseApi}/users/${userId}`;
    return axios
      .get<IUserLookupModel>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getUser = (roleName: string) => {
    const url = `${lookupBaseApi}/users?profile=${roleName}`;
    return axios
      .get<IUserLookupModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getUserWithStartWith = (roleName: SearchType, nameContains: string, signal?: AbortSignal) => {
    const url = `${lookupBaseApi}/users?profile=${roleName}&nameContains=${nameContains}`;
    return axios.get<IUserLookupModel[]>(url, this.setAxiosConfig(signal)).then((res) => res.data);
  };

  getUserWithStartWithNameOnly = (nameContains: string, signal?: AbortSignal) => {
    const url = `${lookupBaseApi}/users?nameContains=${nameContains}`;
    return axios.get<IUserLookupModel[]>(url, this.setAxiosConfig(signal)).then((res) => res.data);
  };

  getTrekSeasons = (trekId: number) => {
    const url = `${lookupBaseApi}/seasons?trekId=${trekId}`;
    return axios
      .get<ITrekSeasons[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getBatchPromoters = () => {
    const url = `${lookupBaseApi}/batch-promoters`;
    return axios
      .get<BatchPromoter[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getTags = () => {
    const url = `${lookupBaseApi}/tags`;
    return axios
      .get<ITags[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getVoucherTypes = () => {
    const url = `${lookupBaseApi}/voucher-types`;
    return axios
      .get<VoucherType[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getTagsPromise = () => {
    const url = `${lookupBaseApi}/tags`;
    axios.get<ITags[]>(url, { headers: getHeaderWithBearerToken() });
  };

  getBatchForBookings = (trekId: number) => {
    const url = `${lookupBaseApi}/batches-for-bookings?trekId=${trekId}`;
    return axios
      .get<IBookingBatch[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getSpecialTrekTypes = () => {
    const url = `${lookupBaseApi}/special-treks`;
    return axios
      .get<ISpecialTrekTypeModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getCancellationReasons = () => {
    const url = `${lookupBaseApi}/cancellation-reasons`;
    return axios
      .get<ICancellationReasonModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getUserFlags = () => {
    const url = `${lookupBaseApi}/user-flags`;
    return axios.get(url, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  getAllUsersByRole = (roleName: string) => {
    const url = `${process.env.REACT_APP_TMS_BACKEND_URL}/management/users`;
    return axios
      .get(url, {
        params: {
          roleName,
          pageSize: 1000,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  getUserByEmail = async (email: string, signal?: AbortSignal) => {
    const url = `${lookupBaseApi}/search-user?email=${email}`;
    const { data } = await axios.get<IUserLookupModel[]>(url, this.setAxiosConfig(signal));
    return data;
  };

  getUserByPhoneNumber = async (phoneNumber: string, signal?: AbortSignal) => {
    const url = `${lookupBaseApi}/search-user?phoneNumber=${phoneNumber}`;
    const { data } = await axios.get<IUserLookupModel[]>(url, this.setAxiosConfig(signal));
    return data;
  };
}

const lookupApi = new LookupService();
export default lookupApi; // exporting like a singleton
