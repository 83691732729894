/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import './Normal_view.scss';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import BookingParticipantService from '../../../api/BookingParticipantService';
import { BookingParticipantState, IbookingsParticipantsList } from '../../../models/Booking/BookingParticipantsList';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getFlagTextColor } from '../../User/UserFlags/helper';

type ComponentProperties = {
  mode?: boolean;
  id?: number;
  history?: any;
};

const BatchConfirmedViewComponent: React.FC<ComponentProperties> = (props) => {
  // @ts-ignore
  let id: number = props.id;
  const toast = useRef(null);

  const [participants, setParticipants] = useState<IbookingsParticipantsList[]>();
  const [selectedParticipants, setSelectedParticipants] = useState<IbookingsParticipantsList>();
  const [loading, setLoading] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [bookingId, setbookingId] = useState(0);
  const dt = useRef(null);

  useEffect(() => {
    const bookingParticipantService = BookingParticipantService;
    bookingParticipantService.getCompletedBookingByBatchId(0, 1000, id).then((data: IbookingsParticipantsList[]) => {
      const participantsData = data.map((part) => ({
        ...part,
        firstName: part.userDetailsForDisplay.firstName,
        userReferenceId: part.userDetailsForDisplay.userReferenceId,
        lastName: part.userDetailsForDisplay.lastName,
        height: part.userDetailsForDisplay.height,
        weight: part.userDetailsForDisplay.weight,
        bmi: part.userDetailsForDisplay.bmi,
        phone: part.userDetailsForDisplay.phone,
        email: part.userDetailsForDisplay.email,
        dob: part.userDetailsForDisplay.dob,
        gender: part.userDetailsForDisplay.gender,
        city: part.userDetailsForDisplay.city,
        emergencyContactNumber: part.userDetailsForDisplay.emergencyContactNumber,
        age: part.userDetailsForDisplay.dob ? calculateAge(part.userDetailsForDisplay.dob) : '',
        userComment: part.userDetailsForDisplay.comment?.commentText,
      }));

      setParticipants(participantsData);
      setLoading(false);
      setIsShown(true);
    });
  }, [bookingId]);

  //calculate age
  const calculateAge = (birthday: string | number | Date) => {
    //milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
    let today = new Date(),
      //birthay has 'Dec 25 1998'
      dob = new Date(birthday),
      //difference in milliseconds
      diff = today.getTime() - dob.getTime(),
      //convert milliseconds into years
      years = Math.floor(diff / 31556736000),
      //1 day has 86400000 milliseconds
      days_diff = Math.floor((diff % 31556736000) / 86400000),
      //1 month has 30.4167 days
      months = Math.floor(days_diff / 30.4167),
      days = Math.floor(days_diff % 30.4167);

    // console.log(`${years} years ${months} months ${days} days`);
    return `${years} years`;
  };

  const bodyTemplate = (data: any, props: any) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data[props.field]}
      </span>
    );
  };

  const flagTemplate = (data: any, props: any) => {
    const textColor = getFlagTextColor(data.userDetailsForDisplay, props);
    return (
      <span style={{ color: textColor }} className="p-column-title">
        {data?.userDetailsForDisplay?.flag?.title}
      </span>
    );
  };

  const participantStatusbodyTemplate = (data: any, props: any) => {
    const bookingParticipantState = data[props.field];
    switch (bookingParticipantState) {
      case BookingParticipantState.APPROVED:
        return <span className="status-success">Approved</span>;
      case BookingParticipantState.PENDING_APPROVAL:
        return <span className="status-dark">Pending</span>;
      case BookingParticipantState.FITNESS_REJECTED:
        return <span className="status-dark">Rejected</span>;
      case BookingParticipantState.WAITING_LIST:
        return <span className="status-warning">Waiting List</span>;
      default:
        return <span className="status-danger">{bookingParticipantState}</span>;
    }
  };

  const bookingStatusbodyTemplate = (data: any, props: any) => {
    const status = data[props.field];
    return (
      <>
        {status === 'COMPLETED' ? (
          <span className="status-success">Completed</span>
        ) : status === 'PAYMENT' ? (
          <span className="status-dark">Payment</span>
        ) : status === 'ADD_PARTICIPANTS' ? (
          <span className="status-dark">Add Participant</span>
        ) : status === 'WAITING_LIST' ? (
          <span className="status-dark">Waiting List</span>
        ) : status === 'CANCELLED' ? (
          <span className="status-danger">Cancelled</span>
        ) : status === 'INACTIVE' ? (
          <span className="status-warning">Inactive</span>
        ) : status === 'DIFFICULT_TREK' ? (
          <span className="status-danger">Difficult Trek</span>
        ) : (
          <span className="status-warning"> NA </span>
        )}
      </>
    );
  };

  const BookingsParticipantsTableHeader = (
    <div className="table-header">
      <h4 className="p-m-0"></h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e: any) => {
            setGlobalFilter(e.target.value);
          }}
          placeholder="Global Search"
        />
      </span>
    </div>
  );

  const actionTemplate = (data: any) => {
    return (
      <>
        <div className="d-flex align-items-center align-jus-con p-my-2">
          <div>
            <Link to={`/bookings/view/${data.bookingId}`} target="_blank">
              <i className="pi pi-eye icon-success-color" color="secondary"></i>
            </Link>
          </div>
        </div>
      </>
    );
  };

  const exportCSV = (selectionOnly: any) => {
    if (dt != null && dt.current != null) {
      // @ts-ignore
      dt.current.exportCSV({ selectionOnly });
    }
  };

  // @ts-ignore
  return (
    <div>
      <div className="p-grid table-demo">
        <Toast ref={toast} />
        <div className="p-col-12">
          <div className="flex align-items-center export-buttons">
            <Button label="Export CSV" onClick={() => exportCSV(false)} className="p-button-success" />
          </div>
          <div>
            <DataTable
              ref={dt}
              value={participants}
              paginator
              scrollable
              style={{ width: '100%' }}
              rows={10}
              dataKey="id"
              tabIndex={0}
              rowHover
              selection={selectedParticipants}
              onSelectionChange={(e: any) => setSelectedParticipants(e.value)}
              globalFilter={globalFilter}
              emptyMessage="No record(s) found."
              header={BookingsParticipantsTableHeader}
              loading={loading}
            >
              <Column
                field="firstName"
                header="First Name"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="lastName"
                header="Last Name"
                headerStyle={{ width: '110px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column field="participantId" header="Trekker Id" style={{ display: 'none' }} />
              <Column
                field="email"
                header="Email"
                headerStyle={{ width: '180px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column field="phone" header="Phone Number" headerStyle={{ width: '100px' }} body={bodyTemplate}></Column>
              <Column
                field="height"
                header="Height"
                headerStyle={{ width: '50px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="weight"
                header="Weight"
                headerStyle={{ width: '50px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column field="bmi" header="Bmi" headerStyle={{ width: '50px' }} sortable body={bodyTemplate}></Column>
              <Column field="age" header="Age" headerStyle={{ width: '75px' }} sortable body={bodyTemplate}></Column>
              <Column field="dob" header="Dob" headerStyle={{ width: '75px' }} sortable body={bodyTemplate}></Column>
              <Column
                field="gender"
                header="Gender"
                headerStyle={{ width: '75px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="userDetailsForDisplay.documentIdNumber"
                header="Document ID Number"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactName"
                header="Emergency Contact Name"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactRelationshipToYou"
                header="Relationship"
                style={{ display: 'none' }}
              />
              <Column
                field="userDetailsForDisplay.emergencyContactEmail"
                header="Emergency Contact Email"
                style={{ display: 'none' }}
              />
              <Column field="emergencyContactNumber" header="Emergency Contact Number" style={{ display: 'none' }} />
              <Column field="city" header="City" headerStyle={{ width: '90px' }} sortable body={bodyTemplate}></Column>
              <Column
                field="pickupLocation"
                header="Pickup Location"
                headerStyle={{ width: '130px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="dropOffLocation"
                header="Dropoff Location"
                headerStyle={{ width: '120px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="bookingParticipantState"
                header="Participant Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={participantStatusbodyTemplate}
              ></Column>
              <Column
                field="bookingState"
                header="Booking Status"
                headerStyle={{ width: '80px' }}
                sortable
                body={bookingStatusbodyTemplate}
              ></Column>
              <Column
                field="backpackOffloadingState"
                header="Backpack"
                headerStyle={{ width: '80px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="comments"
                header="Comment"
                headerStyle={{ width: '120px' }}
                sortable
                body={bodyTemplate}
              ></Column>
              <Column field="flag" header="User Flag" headerStyle={{ width: '60px' }} body={flagTemplate} sortable />
              <Column
                field="userComment"
                header="User Comment"
                headerStyle={{ width: '120px' }}
                sortable
                body={bodyTemplate}
              />
              <Column
                headerStyle={{ width: '100px', textAlign: 'center' }}
                // bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                body={actionTemplate}
                header="Action"
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchConfirmedViewComponent;
