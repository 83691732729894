import axios from 'axios';
import {
  ITrekFormModel,
  ITrekListModel,
  ITrekLookupGetOptions,
  ITrekLookupModel,
  IWayPointsModel,
} from '../models/index';
import { ITrekApiModel } from '../models/Trek/TrekApiModel';
import { ITerms } from '../models/Trek/Terms';
import { IItineraryModel } from '../models/Trek/ItineraryModel';
import { ICampsiteApiModel } from '../models/Trek/CampsiteApiModel';
import { getHeaderWithBearerToken } from '../helpers';

export const trekBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/treks`;

class TrekService {
  lookupGetUrl = `${process.env.REACT_APP_TMS_BACKEND_URL}/lookups/treks`;

  lookupGetUrlForReview = `${process.env.REACT_APP_TMS_BACKEND_URL}/lookups/treks?trekForReview=true`;

  getAxiosInstance = () => {
    return axios;
  };

  get = () => {
    const url = `${trekBaseApi}`;
    return axios
      .get<ITrekListModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getByLookupFormat = (trekLookupOptions?: ITrekLookupGetOptions) => {
    const { fetchFamilyNamedTreks = true } = trekLookupOptions || {};
    const url = `${this.lookupGetUrl}?familyNamedTreks=${fetchFamilyNamedTreks}`;
    return axios
      .get<ITrekLookupModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getByLookupFormatPromise = () => {
    const url = `${process.env.REACT_APP_TMS_BACKEND_URL}/lookups/treks`;
    return axios.get<ITrekLookupModel[]>(url, { headers: getHeaderWithBearerToken() });
  };

  getById = (trekId: number) => {
    const url = `${trekBaseApi}/${trekId}`;
    return axios
      .get<ITrekApiModel>(url, {
        params: {
          id: trekId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  getTrekTermsById = (trekId: number) => {
    const url = `${trekBaseApi}/${trekId}/terms-and-conditions`;
    return axios
      .get<ITerms[]>(url, {
        params: {
          id: trekId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  getTrekItinerayById = (trekId: number) => {
    const url = `${trekBaseApi}/${trekId}/itineraries`;
    return axios
      .get<IItineraryModel[]>(url, {
        params: {
          id: trekId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  getTrekCampsitesById = (trekId: number) => {
    const url = `${trekBaseApi}/${trekId}/campsites`;
    return axios
      .get<ICampsiteApiModel[]>(url, {
        params: {
          id: trekId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  create = (data: ITrekFormModel) => {
    const url = `${trekBaseApi}`; // TODO-CHECK
    const trekData = {
      name: data.name,
      alternateName: data.alternateName,
      description: data.description,
      locationId: data.locationId?.id,
      startPointLocationId: data.startPointLocationId?.id,
      endPointLocationId: data.endPointLocationId?.id,
      maxAltitude: data.maxAltitude,
      groundCoordinatorId: data.groundCoordinatorId?.id,
      shortName: data.shortName,
      state: data.shortName,
      eligibilityCriteria: data.eligibilityCriteria,
      inclusion: data.inclusion,
      exclusion: data.exclusion,
      batchEmailTemplate: data.batchEmailTemplate,
      difficulty: data.difficulty?.code,
      backpackOffloadingDays: data.backpackOffloadingDays,
      duration: data.duration,
      capacity: data.capacity,
      waitCapacity: data.waitCapacity,
      insuranceAmount: data.insuranceAmount,
      weekendTrek: data.weekendTrek,
      idProofRequired: data.idProofRequired,
      receiptRequired: data.receiptRequired,
      wayPoints: this.buildWayPoints(data),
      alternateTrekIds: this.buildAlternateTrekIds(data),
      campsites: data.campsites,
      termsAndConditions: data.termsAndConditions,
      itineraries: data.itineraries,
    };

    return axios
      .post<ITrekApiModel>(url, trekData, { headers: getHeaderWithBearerToken() })
      .then((res) => {
        return res.data;
      });
  };

  createOrUpdateTerm = (trekId: number, data: ITerms) => {
    const url = `${trekBaseApi}/${trekId}/terms-and-conditions`;
    const dt = {
      id: data?.id,
      trekId: data.trekId,
      title: data.title,
      content: data.content,
    };

    return dt.id > 0
      ? axios.put(url, dt, { headers: getHeaderWithBearerToken() }).then((res) => res.data)
      : axios.post(url, dt).then((res) => res.data);
  };

  createOrUpdateItinerary = (trekId: number, data: IItineraryModel, position: number) => {
    const url = `${trekBaseApi}/${trekId}/itineraries`;
    const dt = {
      id: data?.id,
      trekId: data.trekId,
      details: data.details,
      position,
    };

    return dt.id > 0
      ? axios.put(url, dt, { headers: getHeaderWithBearerToken() }).then((res) => res.data)
      : axios.post(url, dt).then((res) => res.data);
  };

  deleteTerm = (trekId: number, termId: number) => {
    const url = `${trekBaseApi}/${trekId}/terms-and-conditions//${termId}`;
    return axios.delete(url, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  deleteItinerary = (trekId: number, itineraryId: number) => {
    const url = `${trekBaseApi}/${trekId}/itineraries//${itineraryId}`;
    return axios.delete(url, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  deleteCampSite = (trekId: number, campSiteId: number) => {
    const url = `${trekBaseApi}/${trekId}/campsites/${campSiteId}`;
    return axios.delete(url, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };

  buildWayPoints = (data: ITrekFormModel) => {
    const items: IWayPointsModel[] = [];
    data.pickUpLocations?.map((x) => items.push({ locationId: x.id, type: 'PICKUP' }));
    data.dropOffLocations?.map((x) => items.push({ locationId: x.id, type: 'DROP_OFF' }));
    return items;
  };

  buildAlternateTrekIds = (data: ITrekFormModel) => {
    const items: number[] = [];
    data.alternateTrekIds?.map((x) => items.push(x.trekId));
    return items;
  };

  update = (id: number, data: ITrekFormModel) => {
    const url = `${trekBaseApi}`;
    const trekData = {
      id,
      name: data.name,
      alternateName: data.alternateName,
      description: data.description,
      locationId: data.locationId?.id,
      startPointLocationId: data.startPointLocationId?.id,
      endPointLocationId: data.endPointLocationId?.id,
      maxAltitude: data.maxAltitude,
      groundCoordinatorId: data.groundCoordinatorId?.id,
      shortName: data.shortName,
      state: data.shortName,
      eligibilityCriteria: data.eligibilityCriteria,
      inclusion: data.inclusion,
      exclusion: data.exclusion,
      batchEmailTemplate: data.batchEmailTemplate,
      difficulty: data.difficulty?.code,
      backpackOffloadingDays: data.backpackOffloadingDays,
      duration: data.duration,
      capacity: data.capacity,
      waitCapacity: data.waitCapacity,
      insuranceAmount: data.insuranceAmount,
      weekendTrek: data.weekendTrek,
      idProofRequired: data.idProofRequired,
      receiptRequired: data.receiptRequired,
      wayPoints: this.buildWayPoints(data),
      alternateTrekIds: this.buildAlternateTrekIds(data),
      campsites: data.campsites,
      termsAndConditions: data.termsAndConditions,
      itineraries: data.itineraries,
    };

    return axios.put(url, trekData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };
}

const trekApi = new TrekService();
export default trekApi; // exporting like a singleton
