import axios from 'axios';
import { ISeasonDetailFormModel, ISeasonListModel, ISeasonsApiModel, IUserLookupModel } from '../models/index';
import { ISpecialTrekTypeModel } from '../models/Trek/SpecialTrekModel';
import { getHeaderWithBearerToken } from '../helpers';

export const seasonsBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/seasons`;

// export const locationBaseApi = `http://localhost:9090/api/v1/locations`;

class SeasonService {
  get = () => {
    const url = `${seasonsBaseApi}`;
    return axios
      .get<ISeasonListModel[]>(url, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  getById = (trekSeasonId: number) => {
    const url = `${seasonsBaseApi}/${trekSeasonId}`;
    return axios
      .get<ISeasonsApiModel>(url, {
        params: {
          id: trekSeasonId,
        },
        headers: getHeaderWithBearerToken(),
      })
      .then((res) => res.data);
  };

  create = (data: ISeasonDetailFormModel) => {
    const seasonData = {
      trekId: data.trekId,
      insuranceOpted: data.insuranceOpted,
      startDate: new Date(data.startDate.getTime() + Math.abs(data.startDate.getTimezoneOffset() * 60000)),
      endDate: new Date(data.endDate.getTime() + Math.abs(data.endDate.getTimezoneOffset() * 60000)),
      slopeManagerIds: this.buildUsersList(data.slopeManagerIds),
      transportCoordinatorIds: this.buildUsersList(data.transportCoordinatorIds),
      specialTrekTypes: this.buildSpecialTreksList(data.specialTrekTypes),
      seasonFees: [
        { feeType: 'TREK', amount: data.trekFee },
        { feeType: 'BACKPACK_OFFLOADING', amount: data.backPackOffloading },
        { feeType: 'TENT_SHARING', amount: data.tentSharing },
        { feeType: 'OFFLINE_BACKPACK_OFFLOADING', amount: data.offlineBackPack },
      ],
    };

    const url = `${seasonsBaseApi}`;
    return axios
      .post<ISeasonListModel>(url, seasonData, { headers: getHeaderWithBearerToken() })
      .then((res) => res.data);
  };

  buildUsersList = (selectedUsers: IUserLookupModel[]) => {
    const items: number[] = [];
    selectedUsers?.map((x) => items.push(x.id));

    return items;
  };

  buildSpecialTreksList = (selectedTreks: ISpecialTrekTypeModel[]) => {
    let items: number[] = [];
    items = selectedTreks?.map((x) => x.id);

    return items;
  };

  update = (id: number, data: ISeasonDetailFormModel) => {
    const seasonData = {
      id,
      trekId: data.trekId,
      insuranceOpted: data.insuranceOpted,
      startDate: new Date(data.startDate.getTime() + Math.abs(data.startDate.getTimezoneOffset() * 60000)),
      endDate: new Date(data.endDate.getTime() + Math.abs(data.endDate.getTimezoneOffset() * 60000)),
      slopeManagerIds: this.buildUsersList(data.slopeManagerIds),
      transportCoordinatorIds: this.buildUsersList(data.transportCoordinatorIds),
      specialTrekTypes: this.buildSpecialTreksList(data.specialTrekTypes),
      seasonFees: [
        { feeType: 'TREK', amount: data.trekFee },
        { feeType: 'BACKPACK_OFFLOADING', amount: data.backPackOffloading },
        { feeType: 'TENT_SHARING', amount: data.tentSharing },
        { feeType: 'OFFLINE_BACKPACK_OFFLOADING', amount: data.offlineBackPack },
      ],
    };

    const url = `${seasonsBaseApi}`;
    // const url='http://localhost:9090/api/v1/seasons'
    return axios.put(url, seasonData, { headers: getHeaderWithBearerToken() }).then((res) => res.data);
  };
}

const seasonApi = new SeasonService();
export default seasonApi; // exporting like a singleton
