import moment from 'moment';

/* eslint-disable */
export const checkForZeroOrNull = (value: number | string): boolean => {
  if (value === 0 || value === null) return true;
  return false;
};

export const checkForZeroOrNullOrUndefined = (value: number | string | undefined): boolean => {
  if (typeof value === 'undefined' || checkForZeroOrNull(value)) return true;
  return false;
};

export const checkForAccurateDataInArray = (valueArray: Array<number | string | undefined>): boolean => {
  for (const value in valueArray) {
    if (checkForZeroOrNullOrUndefined(value)) return true;
  }
  return false;
};

export const compareDates = (date1: Date, date2: Date, allowOverlap: boolean = false): boolean => {
  const date1Instance = new Date(date1);
  const date2Instance = new Date(date2);
  if (allowOverlap) {
    return date1Instance.valueOf() >= date2Instance.valueOf();
  }
  return date1Instance.valueOf() > date2Instance.valueOf();
};

export const getDataWithDebounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

/**
 * a helper that creates an anchor element, sets the download attribute and dispatches an event to export data into a CSV file.
 * @param data
 * @param fileNamePrefix
 */
export function exportToCSV(data: Blob, fileNamePrefix: string) {
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${fileNamePrefix}_Report-${moment().format('DD_MM_YYYY-h_mm_a')}.xlsx`);
  tempLink.click();
  tempLink.remove();
  window.URL.revokeObjectURL(csvURL);
}

/**
 * a helper that calculates age from the date of birth
 * @param birthday
 * @returns
 */
export function calculateAge(birthday: Date) {
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  if (
    today.getMonth() < birthday.getMonth() ||
    (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())
  ) {
    age -= 1;
  }
  return age;
}
