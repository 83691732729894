/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'primereact/button';

import withLoader from '../../helpers/withloader';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';
import BookingParticipantService from '../../api/BookingParticipantService';
import {
  BookingParticipantState,
  IbookingsParticipantsList,
  IParticipantsFilter,
} from '../../models/Booking/BookingParticipantsList';
import { TabPanel, TabView } from 'primereact/tabview';
import BatchNormalViewComponent from './BatchView/Normal_view';
import BatchWaitingListViewComponent from './BatchView/Waiting_List_View';
import BatchRelationshipViewComponent from './BatchView/Relationship_View';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import BatchService from '../../api/BatchService';
import { IBatchApiModel } from '../../models/Batch/BatchApiModel';
import moment from 'moment';
import Comments_View from './BatchView/Comments_View';
import BatchConfirmedViewComponent from './BatchView/Confirmed_view';

const BatchActionViewComponent: React.FC<RouteComponentProps> = ({ history, match }) => {
  const params: any = match.params;
  const id: number = parseInt(params.id, 10);

  const websiteURL: string | undefined = process.env.REACT_APP_WEBSITE_URL;

  let bookingStateDropdownValue: any = [];
  bookingStateDropdownValue.push({ name: 'Registration', code: 'Registration' });
  bookingStateDropdownValue.push({ name: 'Add Participants', code: 'Add_Participants' });
  bookingStateDropdownValue.push({ name: 'Payment', code: 'Payment' });
  bookingStateDropdownValue.push({ name: 'Completed', code: 'Completed' });
  bookingStateDropdownValue.push({ name: 'Waiting List', code: 'Waiting_List' });
  bookingStateDropdownValue.push({ name: 'Cancelled', code: 'Cancelled' });

  let participantStatusDropdownOptions: any = [];
  participantStatusDropdownOptions.push({ name: 'Needs Approval', code: 'NEEDS_APPROVAL' });
  participantStatusDropdownOptions.push({ name: 'Active', code: 'ACTIVE' });
  participantStatusDropdownOptions.push({ name: 'Full', code: 'FULL' });
  participantStatusDropdownOptions.push({ name: 'Waiting List', code: 'WAITING_LIST' });
  participantStatusDropdownOptions.push({ name: 'Closed', code: 'CLOSED' });

  const [participants, setParticipants] = useState<IbookingsParticipantsList[]>();
  const [participantEmail, setParticipantEmail] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const [isShown, setIsShown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [displayGroupMail, setDisplayGroupMail] = useState(false);
  const [displayRevieLink, setdisplayRevieLink] = useState(false);
  const [position, setPosition] = useState('center');
  const [batch, setBatch] = useState<IBatchApiModel>();

  const dialogFuncMap: any = {
    displayGroupMail: setDisplayGroupMail,
    displayRevieLink: setdisplayRevieLink,
  };

  const onOverlayShow = (name: any, position: any) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name: any) => {
    dialogFuncMap[`${name}`](false);
  };

  const acceptClose = () => {
    const tst: any = toast?.current;
    BatchService.doClose(id)
      .then(() => {
        tst.show({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Batch has been closed successfully.',
          life: 3000,
        });
        BatchService.getById(id)
          .then((data) => {
            setBatch(data);
          })
          .catch(() => {
            window.location.reload();
          });
      })
      .catch(() => {
        tst.show({ severity: 'error', summary: 'Error', detail: 'Batch update failed.', life: 3000 });
      });
  };

  const acceptReopen = () => {
    const tst: any = toast?.current;
    BatchService.doReOpen(id)
      .then(() => {
        tst.show({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Batch has been re opened successfully.',
          life: 3000,
        });
        BatchService.getById(id)
          .then((data) => {
            setBatch(data);
          })
          .catch(() => {
            window.location.reload();
          });
      })
      .catch(() => {
        tst.show({ severity: 'error', summary: 'Error', detail: 'Batch update failed.', life: 3000 });
      });
  };

  const reject = () => {};

  const confirmClose = (event: any) => {
    confirmDialog({
      message: 'Are you sure you want to close?',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptClose,
      reject,
    });
  };

  const confirmReOpen = (event: any) => {
    confirmDialog({
      message: 'Are you sure you want to reopen?',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptReopen,
      reject,
    });
  };

  const items = [
    {
      label: 'Dashboard',
      command: () => {
        window.location.hash = '/dashboard';
      },
    },
    {
      label: 'Group',
      command: () => {
        window.location.hash = '/batches';
      },
    },
    { label: 'View' + ' ' + id },
  ];

  const home = { icon: 'pi pi-home', url: '/' };

  useEffect(() => {
    BatchService.getById(id).then((data) => {
      setBatch(data);
    });

    const participantEmailArray: string[] = [];
    let filter: IParticipantsFilter = {
      participantState: BookingParticipantState.APPROVED,
      batchId: id,
    };

    BookingParticipantService.get(0, 1000, filter).then((data: any) => {
      if (data.data != undefined) setParticipants(data.data);

      data.data.forEach((part: IbookingsParticipantsList) => {
        participantEmailArray.push(part.userDetailsForDisplay.email);
      });

      setParticipantEmail(participantEmailArray);

      setLoading(false);
    });

    setLoading(false);
    setIsShown(true);
  }, []);
  ``;

  const commandItems = [
    {
      label: 'Group Email',
      icon: 'pi pi-envelope',
      command: (e: any) => {
        onOverlayShow('displayGroupMail', 'top-left');
      },
    },
    {
      label: 'Backup Offloadings',
      icon: 'pi pi-info-circle',
      command: (e: any) => {
        history.push(`/${id}/backpack`);
      },
    },
    {
      label: 'Approve Participants',
      icon: 'pi pi-user',
      command: (e: any) => {
        history.push(`/${id}/approve_participants`);
      },
    },
    {
      label: 'Review Link',
      icon: 'pi pi-external-link',
      command: (e: any) => {
        onOverlayShow('displayRevieLink', 'top-left');
      },
    },
    {
      label: 'Reviews',
      icon: 'pi pi-eye',
      command: (e: any) => {
        history.push(`/batch_review/${id}`);
      },
    },
    {
      label: 'Cancel Whole Group',
      icon: 'pi pi-times',
      command: () => {
        history.push(`/batches/cancel/${id}`);
      },
    },
  ];

  const goBack = () => {
    window.close();
  };

  const goBookingEdit = () => {
    history.push(`/batches/edit/${id}`);
  };

  const gridTableHeader = (
    <div className="row">
      <div className="d-flex align-items-center">
        <div>
          <div className="d-flex align-items-center">
            <div>
              <SplitButton label="Actions" model={commandItems} className="p-button-info" />
            </div>
            <div className="p-mx-2">
              <Button label="Edit" onClick={goBookingEdit} className="p-button-secondary p-button-outlined" />
            </div>
            <div className="p-mx-2">
              <Button label="Logs" className="p-button-secondary p-button-outlined" />
            </div>
            <div className="p-mx-2">
              <Button label="Back" onClick={goBack} className="p-button-secondary p-button-outlined" />
            </div>
            {batch?.state !== 'CLOSED' ? (
              <div>
                <Button label="Close" onClick={confirmClose} className="p-button-danger" />
              </div>
            ) : (
              <div>
                <Button label="Re Open" onClick={confirmReOpen} className="p-button-danger" />
              </div>
            )}
          </div>
        </div>
      </div>

      <p></p>

      <div>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Confirmed Participants">
            <BatchConfirmedViewComponent id={id} />
          </TabPanel>
          <TabPanel header="All Participants">
            <BatchNormalViewComponent id={id} />
          </TabPanel>
          <TabPanel header="Relationship View">
            <BatchRelationshipViewComponent id={id} />
          </TabPanel>
          <TabPanel header="Waiting List">
            <BatchWaitingListViewComponent id={id} />
          </TabPanel>
          <TabPanel header="Comments">
            <Comments_View id={id} />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );

  const renderGroupMailFooter = (name: any) => {
    return (
      <div>
        <Button label="Send Mail" icon="pi pi-checks" onClick={() => setDisplayGroupMail(false)} />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => setDisplayGroupMail(false)}
          className="p-button-text"
          autoFocus
        />
      </div>
    );
  };

  // @ts-ignore
  return (
    <div>
      <div className="p-grid table-demo">
        <Toast ref={toast} />
        <div className="p-col-12">
          <div className="align-items-center mb-2">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1">
              <div className="display-1">
                {batch?.trekName} Group View {moment(batch?.startDate).format('DD-MMM-YYYY')} to{' '}
                {moment(batch?.endDate).format('DD-MMM-YYYY')}
              </div>
            </div>{' '}
            &nbsp;
          </div>

          {gridTableHeader}
        </div>
      </div>
      <Dialog
        header="Group Mail"
        visible={displayGroupMail}
        style={{ width: '50vw' }}
        footer={renderGroupMailFooter('displayBasic')}
        onHide={() => onHide('displayGroupMail')}
      >
        <div>
          <form>
            <div>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <label htmlFor="pe">Participant emails:</label>
                  <span className="p-float-label p-pt-2">
                    <InputText id="pe" className="w-100" value={participantEmail} />
                  </span>
                </div>
                <div className="p-col-12 p-md-12">
                  <label htmlFor="subjuct">Subject:</label>
                  <span className="p-float-label p-pt-2">
                    <InputText id="subjuct" className="w-100" />
                  </span>
                </div>
                <div className="p-col-12 p-md-12">
                  <label htmlFor="email">Email body:</label>
                  <span className="p-float-label p-pt-2">
                    <Editor style={{ height: '250px' }} />
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        header="Review Link"
        visible={displayRevieLink}
        style={{ width: '50vw' }}
        onHide={() => onHide('displayRevieLink')}
      >
        <p>Review link that can be sent to trekkers of this batch</p>
        <h5>{`${websiteURL}/user-dashboard/user-previous-treks/review/${params.id}`}</h5>
      </Dialog>
    </div>
  );
};

export default withLoader(BatchActionViewComponent);
